import React, {useEffect, useRef, useState} from 'react';
import TypeWriter from '../../components/typewriter';
import Seo from '../../components/seo';
import BoxImage from '../../components/boxImage';
import {INDEX_LINES, SHUFFLE_TEXT} from '../../constants';

const Home = () => {
    useEffect(() => {
        TypeWriter({lines: INDEX_LINES});
    }, []);

    const IMAGE_SRC_URL_TYPES = {
        DEFAULT: 'imageUrl',
        HOVER: 'hoverImageUrl'
    };
    const [imageSrcUrlType, setImageSrcUrlType] = useState(IMAGE_SRC_URL_TYPES.DEFAULT);
    const TopBoxImage = useRef();
    const LeftBoxImage = useRef();
    const RightBoxImage = useRef();
    const BottomBoxImage = useRef();
    const ShuffleButton = useRef();
    const isMobile = window.innerWidth < 768;
    useEffect(() => {
        TopBoxImage.current.src = TopBoxImage.current.dataset[imageSrcUrlType];
        LeftBoxImage.current.src = LeftBoxImage.current.dataset[imageSrcUrlType];
        RightBoxImage.current.src = RightBoxImage.current.dataset[imageSrcUrlType];
        BottomBoxImage.current.src = BottomBoxImage.current.dataset[imageSrcUrlType];
    }, [imageSrcUrlType]);


    const handleOnMouseOver = () => {
        if (isMobile) return;
        setImageSrcUrlType(IMAGE_SRC_URL_TYPES.HOVER);
    };

    const handleOnMouseOut = () => {
        if (isMobile) return;
        setImageSrcUrlType(IMAGE_SRC_URL_TYPES.DEFAULT);
    };

    const handleClick = () => {
        if (!isMobile) return;
        setImageSrcUrlType(imageSrcUrlType === IMAGE_SRC_URL_TYPES.DEFAULT ? IMAGE_SRC_URL_TYPES.HOVER : IMAGE_SRC_URL_TYPES.DEFAULT);
    };

    const getRandomText = () => SHUFFLE_TEXT[Math.floor(Math.random() * SHUFFLE_TEXT.length)];
    const handleShuffleOnMouseOver = () => {
        ShuffleButton.current.title = getRandomText();
        handleOnMouseOver();
    };

    const initialShuffleText = getRandomText();
    return (
        <>
            <div id="main" className="home">
                <Seo title={process.env.REACT_APP_TITLE}
                     canonical={process.env.REACT_APP_CANONICAL}
                     description={process.env.REACT_APP_DESCRIPTION}
                     keywords={process.env.REACT_APP_KEYWORDS}/>
                <div id="icons">
                    <ul className="inline links">
                        <li>
                            <a href={process.env.REACT_APP_ABOUT_URL} target="_blank" rel="noreferrer">@</a>
                        </li>
                    </ul>
                </div>
                <div id="main-content">
                    <div className="container container-xs box-container">
                        <div className="row no-gutters">
                            <div className="col-sm-4"></div>
                            <div className="col-sm-4">
                                <div className="box image-box notransition top-box">
                                    <BoxImage ref={TopBoxImage}
                                          imageSrc={require(`../../static/images/${process.env.REACT_APP_TOP_BOX_IMAGE}`)}
                                          hoverImageSrc={require(`../../static/images/${process.env.REACT_APP_TOP_BOX_IMAGE_HOVER}`)}
                                          onMouseOver={handleOnMouseOver}
                                          onMouseOut={handleOnMouseOut}
                                          onClick={handleClick}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="maintext" id="maintext"></div>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-sm-4">
                                <div className="box image-box notransition left-box">
                                    <BoxImage ref={LeftBoxImage}
                                          imageSrc={require(`../../static/images/${process.env.REACT_APP_LEFT_BOX_IMAGE}`)}
                                          hoverImageSrc={require(`../../static/images/${process.env.REACT_APP_LEFT_BOX_IMAGE_HOVER}`)}
                                          onMouseOver={handleOnMouseOver}
                                          onMouseOut={handleOnMouseOut}
                                          onClick={handleClick}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="box notransition shuffle-box d-none d-lg-block"
                                     onMouseOver={handleShuffleOnMouseOver}
                                     onMouseOut={handleOnMouseOut}>
                                    <img alt="" ref={ShuffleButton}
                                         title={initialShuffleText}
                                         src={require('../../static/images/icons/swimming-white.png')}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="box image-box notransition right-box">
                                    <BoxImage ref={RightBoxImage}
                                              imageSrc={require(`../../static/images/${process.env.REACT_APP_RIGHT_BOX_IMAGE}`)}
                                              hoverImageSrc={require(`../../static/images/${process.env.REACT_APP_RIGHT_BOX_IMAGE_HOVER}`)}
                                              onMouseOver={handleOnMouseOver}
                                              onMouseOut={handleOnMouseOut}
                                              onClick={handleClick}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-sm-4"></div>
                            <div className="col-sm-4">
                                <div className="box image-box notransition bottom-box">
                                    <BoxImage ref={BottomBoxImage}
                                              imageSrc={require(`../../static/images/${process.env.REACT_APP_BOTTOM_BOX_IMAGE}`)}
                                              hoverImageSrc={require(`../../static/images/${process.env.REACT_APP_BOTTOM_BOX_IMAGE_HOVER}`)}
                                              onMouseOver={handleOnMouseOver}
                                              onMouseOut={handleOnMouseOut}
                                              onClick={handleClick}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-4"></div>
                        </div>
                    </div>
                    <div id="seo">
                        <div className="invisible" id="seo_content">
                            {INDEX_LINES}
                        </div>
                    </div>
                </div>
            </div>
            <footer className="footer">
                <div className="container-fluid">
                    <span>
                        <a rel="license" href="https://creativecommons.org/licenses/by-nd/3.0/">
                            <img alt="Creative Commons License" style={{borderWidth: 0}}
                                 src="https://i.creativecommons.org/l/by-nd/3.0/88x31.png"/>
                        </a>
                        <br/>
                        <a rel="license" href="https://creativecommons.org/licenses/by-nd/3.0/">
                            Creative Commons Attribution-NoDerivs 3.0 Unported License
                        </a>
                        <br/>
                        Copyright &copy;&nbsp;{(new Date().getFullYear())}&nbsp;<a href={process.env.REACT_APP_CANONICAL}>{process.env.REACT_APP_TITLE}</a>&nbsp;All Rights Reserved.
                    </span>
                </div>
            </footer>
        </>
    );
};

export default Home;
