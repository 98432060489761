import React, {useEffect} from 'react';
import Seo from '../../components/seo'

const PageNotFound = () => {
    useEffect(() => {
        const body = document.body;

        body.style.animationName = 'light';
        body.style.animationDuration = '10s';
        body.style.animationTimingFunction = 'ease in out';
        body.style.animationIterationCount = 'infinite'
        body.style.background = '#243442';
        body.style.color = '#ffc300'
        body.style.overflow = 'hidden';
    });

    return (
        <div className="error-page">
            <Seo title="Error" />
            <div id="wrapper">
                <div id="main">
                    <div id="clouds1"></div>
                    <div id="clouds2"></div>
                    <div id="sign"></div>
                    <div id="rain"></div>
                    <footer id="footer">
                        <a href={process.env.REACT_APP_CANONICAL}>{process.env.REACT_APP_ERROR_TEXT}</a>
                    </footer>
                </div>
            </div>
        </div>
    );
};

export default PageNotFound;
