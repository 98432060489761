import React from 'react';

const BoxImage = React.forwardRef(({imageSrc, hoverImageSrc, onMouseOver, onMouseOut, onClick}, ref) => {
    return (
        <img alt="" ref={ref}
             src={imageSrc}
             data-image-url={imageSrc}
             data-hover-image-url={hoverImageSrc}
             onMouseOver={onMouseOver}
             onMouseOut={onMouseOut}
             onClick={onClick}
        />
    );
});

export default BoxImage;
