
export const INDEX_LINES = [
    'Jenna Gretsch',
    'Recovering techy — ',
    'Nunc est natandum — ',
    'now it is to be swum!'];
export const SHUFFLE_TEXT = [
    'happy day',
    'everything coming up peaches',
    'open your skies',
    'which bird was that?',
    'I walked. Your turn.',
    'motionless/full',
    'you win the free lunch!',
    'Hot cocoA!',
    'Thelma or Louise?',
    'What\'s your thread count?',
    'Underslung like the best.',
    'shake your snow globe',
    'bumble bee',
    'apis mellifera',
    'pollen pants',
    'ash / life',
    'dazzle your light or something',
    'carpe vinum',
    'popcorn is rad',
    'ripple',
    'reappear as if new',
    'kiss you',
    'just a nibble. A NIBBLE.',
    'wiley as a parasite',
    'dance it',
    'care bc it\'s better',
    'be fine with foolish',
    'crack that mind carefully but wild',
    'be my tidal wave'];
