const TypeWriter = ({lines}) => {
    const lineCallback = (i) => {
        // when we've finished a line of type, start a new one
        const index = i + 1;
        const paragraph = container.getElementsByTagName('p')[index];
        if (paragraph) {
            typeLine(paragraph, index);
        }
    }

    const typeLetter = (lineIndex, letterIndex, span, line, callback) => {
        // add the letter
        span.textContent = span.textContent + line[letterIndex];
        if (letterIndex < line.length - 1) {
            // Add another letter (after a delay)
            setTimeout(() => typeLetter(lineIndex, letterIndex + 1, span, line, lineCallback), Math.floor((Math.random() * 25) + 20));
        } else if (callback) {
            // We've reached the end of the line, callback after a short delay
            callback(lineIndex);
        }
    };

    const typeLine = (paragraph, index) => {
        const span = paragraph.getElementsByTagName('span')[0];
        if (span) {
            if (lines[index] === '&nbsp;') {
                span.innerHTML = '&nbsp;';
                lineCallback(index);
            } else if (lines[index]) {
                typeLetter(index, 0, span, lines[index], lineCallback);
            }
        }
    };

    const container = document.getElementById('maintext');

    // Create our paragraph tags
    for (let i = 0, l = lines.length; i < l; i++) {
        const p = document.createElement('p');
        p.innerHTML = '<span class="content"></span>';
        container.appendChild(p);
    }

    // Select first paragraph, add the insertion point, start typing
    const paragraph = container.getElementsByTagName('p')[0];
    typeLine(paragraph, 0);
};

export default TypeWriter;
