import React from 'react'
import Helmet from 'react-helmet'

const DEFAULT_SITE_TITLE = process.env.REACT_APP_TITLE;
const DEFAULT_CANONICAL = process.env.REACT_APP_CANONICAL;

const Seo = ({title, canonical, description, keywords, noIndex, children, ...props}) => {
    const fullTitle = title || DEFAULT_SITE_TITLE;
    const canonicalUrl = canonical || DEFAULT_CANONICAL;

    return (
        <Helmet {...props}>
            <title>{fullTitle}</title>
            <link rel="canonical" href={canonicalUrl} />
            {description && <meta name="description" content={description} />}
            {keywords && <meta name="keywords" content={keywords} />}
            {noIndex && <meta name="robots" content="noindex" />}
            {children}
        </Helmet>
    )
}

export default Seo
